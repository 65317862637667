<template>
  <picture :class="xClass">
    <source
      v-if="desktop1"
      media="(min-width: 1440px)"
      :srcset="`/images/${path}/desktop-1.webp`"
    />
    <source
      v-if="desktop2"
      media="(min-width: 1280px)"
      :srcset="`/images/${path}/desktop-2.webp`"
    />
    <source v-if="tablet1" media="(min-width: 1024px)" :srcset="`/images/${path}/tablet-1.webp`" />
    <source v-if="tablet2" media="(min-width: 768px)" :srcset="`/images/${path}/tablet-2.webp`" />
    <source :srcset="`/images/${path}/mobile.webp`" />

    <img
      :alt="alt"
      :decoding="loadAsync ? 'async' : undefined"
      :loading="loadLazy ? 'lazy' : undefined"
    />
  </picture>
</template>

<script>
export default {
  name: 'PartialsBackground',
  props: {
    path: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    xClass: {
      type: String,
      required: false,
      default: '',
    },
    loadAsync: {
      type: Boolean,
      required: false,
      default: true,
    },
    loadLazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    desktop1: {
      type: Boolean,
      required: false,
      default: true,
    },
    desktop2: {
      type: Boolean,
      required: false,
      default: true,
    },
    tablet1: {
      type: Boolean,
      required: false,
      default: true,
    },
    tablet2: {
      type: Boolean,
      required: false,
      default: true,
    },
    mobile: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
